.Showcase__Content__wrapper {
    display: table;
    margin: 0 auto
}


.Showcase__style__list {
    width: 100%;
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #999
}

.Showcase__style__item {
    position: relative;
    border-bottom: 1px solid #999
}

.Showcase__style__stylizedList {
    position: relative;
    z-index: 0;
    background-color: #f3f3f3;
    border: 1px solid #efefef;
    border-radius: 3px;
    outline: none;
    width: 100%
}

.Showcase__style__stylizedItem {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 400
}

.Showcase__style__handle {
    position: relative;
    top: 1px;
    display: block;
    width: 18px;
    height: 11px;
    opacity: .25;
    margin-right: 20px;
    cursor: row-resize;
    background: -webkit-linear-gradient(top,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
    background: linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000)
}



